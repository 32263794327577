import React from "react";
import Intro from "./Sections/Intro";
// import Clients from "./Home/Clients";
import Metrics from "./Home/Metrics";
// import Features from "./Sections/Features/Features";
// import Partnerships from "./Sections/Partnerships/Partnerships";
// import Testimonials from "./Sections/Testimonials/Testimonials";

const Home = () => {
  return (
    <>
      <Intro />
      <Metrics />
      {/* <Clients /> */}
      {/* <Features /> */}
      {/* <Partnerships /> */}
      {/* <Testimonials /> */}
    </>
  );
};

export default Home;
