export const allProductDetails = [
  {
    id: "surveillance",
    name: "HawkEye Surveillance™",
    preview: `Advanced reservoir surveillance powered by HawkEye engine, to characterize the reservoir and optimize field injection (water, CO2,polymer,surfactant) operations`,
    description: `<ul style="list-style: circle;">
    <li>
<b>Hybrid Model Workflows:</b> Combines CRM, FMM, and ML to characterize reservoirs and optimize injection strategies, including water, gas, WAG, polymer, and surfactant injections.
    </li>
    <li>
<b>Connectivity Analysis:</b> Identifies effective and ineffective injectors, quantifies their impact on oil production, and prioritizes well maintenance activities.
    </li>
    <li>
<b>Multilayer Analysis: </b>Assesses fluid and oil production from individual reservoir layers, enhancing the understanding of subsurface dynamics.
    </li>
    <li>
<b>Advanced Optimization:</b> Determines optimal injection rates and targets based on current field constraints to maximize production efficiency.    </li>
<li><b>Versatile Application:</b> Suitable for mature conventional fields and new unconventional plays like Shale Gas and Tight Oil.
</li>
    </ul>
    `,
    image:
      "https://images.unsplash.com/photo-1496247749665-49cf5b1022e9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1473&q=80",
  },
  {
    id: "fdp",
    name: "HawkEye FDP™",
    preview:
      "Field Development Planning solution to accelerate optimal well placements and trajectories, maximizing NPV for greenfield and brownfield projects",
    description: `
   <ul style="list-style: circle;">
    <li>
        <b>Hybrid Model Workflows:</b> Combines reservoir simulations, reduced physics (FMM), and ML to rapidly identify optimal well locations and trajectories for greenfields and brownfields.
    </li>
    <li>
        <b>Proxy Reservoir Recovery Maps:</b> Generates quick recovery maps from a single simulation input file, visualizing potential well performance to guide placement decisions.
    </li>
    <li>
        <b>Advanced Well Placement Optimization:</b> Refines well placements and trajectories using HawkEye’s advanced optimization algorithms, maximizing field NPV.
    </li>
    <li>
        <b>Rapid Validation with FMM:</b> Validates optimal well placements using static models and FMM workflows, achieving over 90% consistency with full-scale simulations.
    </li>
    <li>
        <b>Enhanced Seismic Analysis:</b> Integrates deep learning to improve seismic inversion, enhancing the accuracy of static models used in well placement decisions.
    </li>
</ul>

    `,
    image:
      "https://images.unsplash.com/photo-1588011930968-eadac80e6a5a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80",
  },
  {
    id: "achm",
    name: "HawkEye ACHM™",
    preview: `Accelerated History Matching with HawkEye’s advanced ML and optimization algorithms to drastically reduce history marching times for reservoir simulations`,
    description: `
    <ul style="list-style: circle;">
    <li>
        <b>Hybrid Model Workflows:</b> Speeds up history matching in brownfields by combining reservoir simulations with ML to enhance workflow efficiency.
    </li>
    <li>
        <b>Key Parameter Identification:</b> Detects critical factors affecting the history matching process, enabling targeted adjustments for improved match quality.
    </li>
    <li>
        <b>Uncertainty Analysis:</b> Integrates multiple realizations to incorporate uncertainties, providing a comprehensive understanding of reservoir dynamics.
    </li>
    <li>
        <b>Advanced Optimization:</b> Uses HawkEye’s optimization algorithms to generate history-matched realizations, ensuring accurate and reliable outputs.
    </li>
    <li>
        <b>Streamlined Simulation:</b> Produces history-matched simulation files with optimal parameters from a single input, simplifying the overall process.
    </li>
</ul>

    `,
    image:
      "https://images.unsplash.com/photo-1513828646384-e4d8ec30d2bb?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    id: "prodwise",
    name: "HawkEye ProdWise™",
    preview: `Production management solution enabling decline analysis with advanced multi-well DCA, uncertainty forecasting, and ML-driven decline estimates`,
    description: `
   <ul style="list-style: circle;">
    <li>
        <b>Browser-Based Decline Analysis:</b> Perform single and multi-well decline curve analysis (DCA) simultaneously on an intuitive, web-based platform.
    </li>
    <li>
        <b>ML-Driven Decline Forecasting:</b> Leverages LSTM deep learning models to enhance decline forecasts with 80% training and 20% testing for high accuracy.
    </li>
    <li>
        <b>Uncertainty Forecasting:</b> Generate P10, P50, and P90 production forecasts with integrated uncertainty analysis for more informed decision-making.
    </li>
    <li>
        <b>Automated Multi-Well Autofit:</b> Automatically selects the optimal decline periods and algorithms for multiple wells, streamlining the analysis process.
    </li>
    <li>
        <b>Multisegmented DCA:</b> Conduct segmented DCA to capture varying reservoir conditions, identifying different production mechanisms over time.
    </li>
</ul>

    `,
    image:
      "https://images.unsplash.com/photo-1518704618243-b719e5d5f2b8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80",
  },
  {
    id: "co2",
    name: "HawkEye CO2 Optimizer™",
    preview: `Hybrid models for CO₂ injection optimization and asset ranking to drive effective abatement strategies`,
    description: `
    <ul style="list-style: circle;">
    <li>
        <b>Hybrid CO₂ Injection Workflows:</b> Combines CRM, FMM, and ML models for CO₂ abatement in mature conventional fields (continuous injection) and unconventional fields (CO₂ huff n puff).
    </li>
    <li>
        <b>Reservoir Characterization and Injector Efficiency:</b> Identifies effective and ineffective injectors to optimize CO₂ injection strategies and enhance reservoir performance.
    </li>
    <li>
        <b>Optimized Huff and Puff Parameters:</b> Uses advanced optimization engines with neural network and simulation-based proxy models to determine the best parameters for CO₂ huff and puff operations.
    </li>
    <li>
        <b>Standardized Emissions Quantification:</b> Integrates production data to quantify CO₂ emissions, supporting ESG reporting and Life Cycle Analysis (LCA) across asset teams.
    </li>
    <li>
        <b>Asset Evaluation and Ranking:</b> Assesses the potential of assets for CO₂ injection, ranking them based on their ability to reduce CO₂ emissions effectively.
    </li>
</ul>

    `,
    image:
      "https://images.unsplash.com/photo-1605307660997-21db274a2c42?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2531&q=80",
  },
  {
    id: "well-log",
    name: "HawkEye Welllog AI™",
    preview: `AI-powered well log analysis for enhanced subsurface evaluations and insights`,
    description: `
   <ul style="list-style: circle;">
    <li>
        <b>Automated Well Log Processing:</b> Utilizes hybrid model workflows with advanced analytics and ML to automate well log pre-processing and interpretation, accelerating data analysis.
    </li>
    <li>
        <b>Seamless Data Harmonization:</b> Directly upload well logs on a browser-based platform to tag and assign families using pre-defined enterprise standards, streamlining data organization.
    </li>
    <li>
        <b>Smart Depth Merging:</b> Combines multiple overlapping depths into a single log using predefined rules, ensuring consistency and accuracy in well log data.
    </li>
    <li>
        <b>Automated Depth Correction:</b> Detects logs needing depth correction and applies cross-correlation algorithms integrated with HawkEye’s optimization engine for precise adjustments.
    </li>
    <li>
        <b>ML-Powered Data Completion:</b> Fills in missing well log sections and classifies lithologies using ML algorithms trained on enterprise data, enhancing data quality and interpretation.
    </li>
</ul>

    `,
    image:
      "https://images.unsplash.com/photo-1539186607619-df476afe6ff1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
  },
  {
    id: "gen-ai",
    name: "Reserv Gen AI™",
    preview: `Generative AI LLM trained on reservoir taxonomy to accelerate asset reviews and automate subsurface reporting workflows`,
    description: `
    <ul style="list-style: circle;">
    <li>
        <b>Domain-Specific Generative AI:</b> Trained locally with enterprise data and Reserv Gen AI engine, setting subsurface guardrails to minimize hallucinations and ensure reliable outputs.
    </li>
    <li>
        <b>Accelerated Asset Reviews:</b> Enables query-based retrieval of data, plots, and reports, streamlining asset reviews without the need for other proprietary software.
    </li>
    <li>
        <b>Automated Reporting:</b> Generates ongoing production and reservoir reports automatically, saving reservoir engineers significant time in daily workflows.
    </li>
    <li>
        <b>Customizable Dashboards:</b> Instantly generate no-code dashboards using data trained on Reserv Gen AI, allowing for quick visualization and decision-making.
    </li>
    <li>
        <b>Integrated Production Databases:</b> Replaces expensive proprietary software by using production databases trained on Reserv Gen AI, reducing costs and enhancing efficiency.
    </li>
</ul>

    `,
    image:
      "https://images.unsplash.com/photo-1578356058390-f58c575337a2?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
  },
];
