import React from "react";

const Metrics = () => {
  return (
    <div className="border-y-2 border-[#207175]/20 md:mt-0 md:mb-20 mt-5 mb-5">
      <div className="container mx-auto px-0">
        <div className="flex flex-wrap">
          <div className="py-10 relative w-[50%] md:w-[25%]">
            <div className="h-[50%] w-[2px] bg-[#D0D4DD] absolute right-0 top-[50%] translate-y-[-50%]"></div>
            <div className="text-center md:text-[16px] px-2 text-[13px]">
              Countries Operated
            </div>
            <div className="text-[#207175] text-[20px] md:text-[48px] font-bold  text-center">
              10+
            </div>
          </div>
          <div className="py-10 relative w-[50%] md:w-[25%]">
            <div className="h-[50%] w-[2px] bg-[#D0D4DD] absolute right-0 top-[50%] translate-y-[-50%]"></div>
            <div className="text-center md:text-[16px] px-2 text-[13px]">
              Clients Empowered
            </div>
            <div className="text-[#207175] text-[20px] md:text-[48px] font-bold  text-center">
              16+
            </div>
          </div>
          <div className="py-10 relative w-[50%] md:w-[25%]">
            <div className="h-[50%] w-[2px] bg-[#D0D4DD] absolute right-0 top-[50%] translate-y-[-50%]"></div>
            <div className="text-center md:text-[16px] px-2 text-[13px]">
              Fields Enhanced
            </div>
            <div className="text-[#207175] text-[20px] md:text-[48px] font-bold  text-center">
              20+
            </div>
          </div>
          <div className="py-10 relative w-[50%] md:w-[25%]">
            <div className="text-center md:text-[16px] px-2 text-[13px]">
              Wells Optimized
            </div>
            <div className="text-[#207175] text-[20px] md:text-[48px] font-bold  text-center">
              50,000+
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Metrics;
